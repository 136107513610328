<template>
  <div class="header-top-box">
    <div class="header-top">
      <div>

        <img src="../assets/logo.png" alt="" />
        <div class="int-path">
          <div v-for="item in data.intPath" :key="item.path" :class="activeIndex === item.path ? 'active' : ''">

            <el-popover popper-class="poper-header" v-if="item.children" placement="bottom-start" :title="item.name" width="250" trigger="hover" @hide="hiden">
              <el-divider></el-divider>
              <div>
                <div v-for="it in item.children " :key="it.path" class="poper-active" @click="clickEr(it.path,it.title)">
                  <div :class="defaultTitle===it.title?'div-active':'cacle'"></div>
                  <div class="tet">
                    <span :class="defaultTitle===it.title?'title-active':'title'">{{it.title}}</span>
                    <span class="text">{{it.text}}</span>
                  </div>
                </div>
              </div>
              <span slot="reference" @click="clickRouter(item.path)">{{ item.title}}</span>
            </el-popover>
            <span v-else @click="clickRouter(item.path)">{{item.title}}</span>
            <div :class="activeIndex === item.path ? 'active-line' : ''"></div>
          </div>
        </div>
      </div>
      <div class="out-path">
        <div v-for="item in data.outPath" :key="item.path">
          <a :href="item.path" target="_blank">{{ item.title }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import headerData from '../mock/headerData.json'
export default {
  name: 'HeaderTop',
  data () {
    return {
      data: headerData,
      activeIndex: window.location.pathname,
      visible: false,
      defaultTitle: ''
    }
  },
  watch: {
    $route (to) {
      console.log(to)
      if (to.path.indexOf('/cpzx') !== -1) {
        this.activeIndex = '/cpzx'
      } else if (to.path.indexOf('/jjfa') !== -1) {
        this.activeIndex = '/jjfa'
      }
      else {
        this.activeIndex = to.path
      }
    }
  },
  methods: {
    clickRouter (path) {
      if (path !== '/cpzx' && path !== '/jjfa') {
        this.$router.replace(path)
      }
    },
    clickEr (path, title) {
      this.$router.replace(path)
      this.defaultTitle = title
    },
    hiden () {
      this.defaultTitle = ''
    },

  }
}
</script>
<style lang="less">
.header-top-box {
  box-shadow: 0 1px #d8d8d8;
}
.header-top {
  max-width: 1200px;
  margin: 0 auto;
  height: 60px;
  font-size: 14px;
  color: #333;
  background: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
  img {
    width: 90px;
    height: 39px;
    margin-right: 67px;
  }
  .int-path {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    div {
      cursor: pointer;
      margin-right: 69px;
      white-space: nowrap;
      height: 60px;
      line-height: 60px;
      position: relative;
      div {
        width: 14px;
        height: 3px;
        margin-right: 0;
        border-radius: 2px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    div:hover > div {
      background: #3a7efb;
    }
  }
  .active {
    color: #3a7efb;
  }
  .active-line {
    background: #3a7efb;
  }
  .out-path {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    div:first-child {
      margin-right: 25px;
      white-space: nowrap;
    }
  }
}
.poper-header {
  top: 48px !important;
  .el-divider {
    margin: 0 0 12px 0;
  }
  .popper__arrow {
    display: none !important;
  }
  .el-popover__title {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
    line-height: 19px;
  }
  .title {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #333333;
  }
  .text {
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #666666;
  }
  .cacle {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #d8d8d8;
  }
  .poper-active:hover {
    .cacle {
      background: #3a7efb;
    }
    .title {
      color: #3a7efb;
    }
  }
  .title-active {
    color: #3a7efb;
    font-size: 14px;
    font-family: MicrosoftYaHei;
  }
  .div-active {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #3a7efb;
  }
  .poper-active {
    display: flex;
    align-items: baseline;
    cursor: pointer;
    margin-bottom: 20px;
    .tet {
      display: flex;
      flex-direction: column;
      margin-left: 15px;
    }
  }
}
</style>

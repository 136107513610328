import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/sy' },
  {
    path: '/sy',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/HomeView.vue'),
  },
  {
    path: '/cpzx',
    name: 'cpzx',
    component: () =>
      import(/* webpackChunkName: "cpzx" */ '../views/CpzxView.vue'),
    children:[
      {
        path:'rccp',
        name:'rccp',
        component:()=>import(/* webpackChunkName: "cpzx" */ '../views/RccpView.vue')
      },
      {
        path:'zxdy',
        name:'zxdy',
        component:()=>import(/* webpackChunkName: "cpzx" */ '../views/ZxdyView.vue')
      },
      {
        path:'csxt',
        name:'csxt',
        component:()=>import(/* webpackChunkName: "cpzx" */ '../views/CsxtView.vue')
      },
      {
        path:'slpg',
        name:'slpg',
        component:()=>import(/* webpackChunkName: "cpzx" */ '../views/SlpgView.vue')
      }
    ]
  },
  {
    path: '/jjfa',
    name: 'jjfa',
    component: () =>
      import(/* webpackChunkName: "jjfa" */ '../views/JjfaView.vue'),
      children:[
        {
          path:'zp',
          name:'zp',
          component:()=>import(/* webpackChunkName: "jjfa" */ '../views/ZpView.vue')
        },
        {
          path:'nbrc',
          name:'nbrc',
          component:()=>import(/* webpackChunkName: "jjfa" */ '../views/NbrcView.vue')
        },
        {
          path:'qt',
          name:'qt',
          component:()=>import(/* webpackChunkName: "jjfa" */ '../views/QtView.vue')
        }
      ]
  },
  {
    path: '/gywm',
    name: 'gywm',
    component: () =>
      import(/* webpackChunkName: "gywm" */ '../views/GywmView.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router

<template>
  <div id="app">
    <el-container>
      <el-header>
        <Header />
      </el-header>
      <el-main id="app-main"> <router-view /></el-main>
    </el-container>
  </div>
</template>

<script>
import Header from './components/Header.vue'
export default {
  name: 'App',
  components: { Header },

  methods: {
    handleScroll () {
      let scrool = document.getElementById('app-main')
      let changheBg = document.getElementsByClassName('header-top')[0]
      let changheBgBox = document.getElementsByClassName('header-top-box')[0]
      if (scrool.scrollTop >= 60) {
        changheBg.style.backgroundColor = '#ffffff'
        changheBgBox.style.backgroundColor = '#ffffff'
      } else {
        // changheBg.style.backgroundColor = '#FFFFFF4d'
        changheBg.style.backgroundColor = 'transparent'
        changheBgBox.style.backgroundColor = '#FFFFFF4d'
      }
    },
  },
  mounted () {
    let scrool = document.getElementById('app-main')
    scrool.addEventListener('scroll', this.handleScroll)
  }
}
</script>


<style lang="less">
html,
body,
#app {
  padding: 0;
  margin: 0;
  height: 100%;
  min-width: 1200px;
  font-size: 10px;
}
a {
  color: #333;
  text-decoration: none;
}
.el-container {
  width: 100%;
  height: 100%;
  .el-header,
  .el-main {
    padding: 0;
    width: 100%;
  }
  .el-header {
    position: fixed;
    top: 0;
    z-index: 100;
  }
}
ul {
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}
</style>
